import styled from "styled-components";

export const Row = styled.div`
    width: 100%;
    height: auto;
    padding: 15px 15px;
    border: 1px solid #D9D9D9;
    justify-content: left;
    display: flex;

    position: relative;
`

export const ContainerArrow = styled.div`
    right: 0;
    padding: 12px;
    border-radius: 4px;
    background: #E6F7EE;
    position:absolute;
    margin-right: 1%;
    align-items: center;
    display: flex;
`

export const ChevronImg = styled.img`
    width: 100%;
    height: 100%;
`

