import React from 'react';
import { ContainerMain, ContainerGrid, ContainerImage, ContainerGridSocial } from './FooterElement';
import { Text, ButtonContainer } from '../../styled/AppElement'
import ImageBackground from '../../assets/images/image-background-wood.png';
import ImageLogo from '../../assets/images/image-footer-logo-white.png';
import Facebook from '../../assets/items/facebook.png';
import Instagram from '../../assets/items/instagram.png';
import Tiktok from '../../assets/items/tiktok.png';
import { RiWhatsappLine } from "react-icons/ri";
import styled from 'styled-components';

const ResponsiveContainerGrid = styled(ContainerGrid)`

  @media (max-width: 350px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ResponsiveContainerGridSocial = styled(ContainerGridSocial)`
  @media (max-width: 350px) {
    justify-content: center;
  }
`;

const ResponsiveText = styled(Text)`
  @media (max-width: 350px) {
    text-align: center;
    font-size: 14px;
  }
`;

export const Footer = (props) => {

    return (
        <ContainerMain style={{backgroundImage:`url(${ImageBackground})`, backgroundSize:"cover", backgroundPosition:"center"}}>
            <ResponsiveContainerGrid>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <ContainerImage src={ImageLogo} style={{maxWidth: '100%', height: 'auto'}}/>
                </div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <ResponsiveText style={{color:"white"}}>{props.text}</ResponsiveText>
                </div>
                <ResponsiveContainerGridSocial>
                    <ButtonContainer  onClick={() => window.open('https://www.facebook.com/profile.php?id=100068384550801', '_blank')}>
                        <ContainerImage src={Facebook} style={{maxWidth: '24px', height: 'auto'}}/>
                    </ButtonContainer>
                    <ButtonContainer onClick={() => window.open('https://www.instagram.com/barriovertical?igsh=MTE2eHZ6cHdxbXF1OA==', '_blank')}>
                        <ContainerImage src={Instagram} style={{maxWidth: '24px', height: 'auto'}}/>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ContainerImage src={Tiktok} style={{maxWidth: '24px', height: 'auto'}}/>
                    </ButtonContainer>
                    <ButtonContainer onClick={() => window.open('https://wa.me/50683022282', '_blank')}>
                        <RiWhatsappLine style={{maxWidth: '24px', height: 'auto', color:"white"}}/>
                    </ButtonContainer>
                </ResponsiveContainerGridSocial>
            </ResponsiveContainerGrid>
        </ContainerMain>
    )
}