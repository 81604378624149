import { ContainerMain, ContainerSections, ContainerLanguages, ButtonContainerNavbar, Hamburger } from './NavbarElement'
import { Text, ButtonContainer } from '../../styled/AppElement'
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';



export default function Navbar() {
    const { t, i18n } = useTranslation(["navbar"]);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setCurrentLanguage(lang);
    };

    const scrollToElement = (elementId) => {
        document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    const navbarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (navbarRef.current && !navbarRef.current.contains(event.target) && isOpen) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <>
            <ContainerMain ref={navbarRef} isOpen={isOpen}>
                <ContainerSections>
                    <ButtonContainerNavbar>
                        <ButtonContainer  onClick={() => scrollToElement("portfolio")}>
                            <Text style={{fontWeight:"700"}}>{t("sections.section1")}</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                    <ButtonContainerNavbar>
                        <ButtonContainer onClick={() => scrollToElement("services")}>
                            <Text style={{fontWeight:"700"}}>{t("sections.section2")}</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                    <ButtonContainerNavbar>
                        <ButtonContainer onClick={() => scrollToElement("about-us")}>
                            <Text style={{fontWeight:"700"}}>{t("sections.section3")}</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                    <ButtonContainerNavbar>
                        <ButtonContainer onClick={() => scrollToElement("philosophy")}>
                            <Text style={{fontWeight:"700"}}>{t("sections.section4")}</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                    <ButtonContainerNavbar>
                        <ButtonContainer onClick={() => scrollToElement("contact")}>
                            <Text style={{fontWeight:"700"}}>{t("sections.section5")}</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                </ContainerSections>
                <ContainerLanguages>
                    <ButtonContainerNavbar>
                        <ButtonContainer 
                            style={{
                                margin: "2px",
                                borderBottom: currentLanguage === 'es' ? '2px solid #C47532' : 'none'
                            }} 
                            onClick={() => changeLanguage("es")}
                        >
                            <Text style={{fontWeight:"700"}}>Es</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                    <ButtonContainerNavbar>
                        <ButtonContainer  
                            style={{
                                margin: "2px",
                                borderBottom: currentLanguage === 'en' ? '2px solid #C47532' : 'none'
                            }} 
                            onClick={() => changeLanguage("en")}
                        >
                            <Text style={{fontWeight:"700"}}>En</Text>
                        </ButtonContainer>
                    </ButtonContainerNavbar>
                </ContainerLanguages>
            </ContainerMain>

            <Hamburger onClick={toggleMenu}>
                &#9776;
            </Hamburger>
        </>
    )
}