import styled from "styled-components";
import { Text } from '../../styled/AppElement'


export const ContainerMain = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 15px 0px #00000026;
    padding: 2% 1% 1% 1%;
`
export const ContainerGrid = styled.div`
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2% 1% 2% 1%;
    gap: 20px;

    @media (max-width: 600px) { /* Tablet y smartphones */
        display: block;
    }
`

export const ContainerTitle = styled.div`
    width: 100%;
    height: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
export const Row = styled.div`
    width: 100%;
    height: 6%;
    display: flex;
    align-items: center;
`
export const Row2 = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    border: 2px solid #DFE4EA;
    border-radius: 10px;
`

export const ContainerRow2 = styled.div`
    height: 24px;

    @media (max-width: 600px) { /* Tablet y smartphones */
        height: 45px;
    }
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    background: white;
    border: none;
`

export const SubmitButton = styled.button`

    background: #B68B2C;
    border: none;
    font-size: 20px;
    cursor: pointer;
    right: 0;

    border-radius: 27px 27px 27px 27px;

    padding: 9px;
    width: 90px;

    position: absolute;
`

export const ContainerSubmit = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;

    position: relative;
`

export const ContainerSubmitText = styled.div`
    width: 76%;

    @media (max-width: 900px) { /* Tablet y smartphones */
        width: 60%;
    }
`

export const Image = styled.img`
    width: 100%;
    @media (max-width: 600px) { /* Tablet y smartphones */
        width: 60%;
    }
`
export const TextRegular = styled(Text)`

    font-size: 16px;

    @media (max-width: 1415px) { /* Tablet y smartphones */
        font-size: 12px;
    }
`
export const TextHelp = styled(Text)`

    font-size: 16px;

    @media (max-width: 1415px) { /* Tablet y smartphones */
        font-size: 12px;
    }
    @media (max-width: 1070px) { /* Tablet y smartphones */
        font-size: 9px;
    }
`

export const ContainerCheck = styled.div`
    width: 9%;
    height: 6%;
    display: flex;
    align-items: center;
`