import styled from "styled-components";

export const ContainerMain = styled.div`
    width: 63%;
    justify-content: center;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 8px 15px 0px #00000026;
    padding: 3% 1% 3% 1%;
    z-index: 1000;
    align-items: center;

    @media (max-width: 1200px) { /* Tablet y smartphones */
        width: 70%;
    }
    @media (max-width: 600px) { /* Tablet y smartphones */
        width: 80%;
    }
`

export const Image = styled.img`
`

export const SubmitButton = styled.button`

    background: black;
    border: none;
    font-size: 20px;
    cursor: pointer;
    right: 0;

    border-radius: 27px 27px 27px 27px;

    padding: 9px;
    width: 90px;
`

export const TextMain = styled.p`

    color: #151515;
    font-size: 45px;
    margin:0;
    font-family: 'Italiana';
    display: flex;
    justify-content:center;

  @media (max-width: 900px) { /* Tablet y smartphones */
    font-size: 30px;
    text-align: left;
  }

  @media (max-width: 400px) { /* Tablet y smartphones */
    font-size: 21px;
  }
`