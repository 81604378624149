import React, { useState } from 'react';
import { Row, ContainerArrow, ChevronImg } from './FAQElement';
import { Text } from '../../styled/AppElement';
import { useTranslation } from 'react-i18next';
import Chevron from '../../assets/items/chevron-down.svg';

export default function FAQ() {
    const { t } = useTranslation(["FAQ"]);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const faqs = [
        { question: t("FAQ.question1"), answer: t("FAQ.response1") },
        { question: t("FAQ.question2"), answer: t("FAQ.response2") },
        { question: t("FAQ.question3"), answer: t("FAQ.response3") },
        { question: t("FAQ.question4"), answer: t("FAQ.response4") },
        { question: t("FAQ.question5"), answer: t("FAQ.response5") },
        { question: t("FAQ.question6"), answer: t("FAQ.response6") }
    ];

    return (
        <div style={{width:"100%", height:"auto"}}>
            {faqs.map((faq, index) => (
                <div key={index}>
                    <Row onClick={() => toggleExpand(index)}>
                        <Text style={{display:"flex", width:"90%", fontWeight:"700", textAlign:"left"}}>{faq.question}</Text>
                        <ContainerArrow>
                            <ChevronImg 
                                src={Chevron} 
                                style={{ 
                                    transform: expandedIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease',
                                    marginLeft:"3%"
                                }} 
                            />
                        </ContainerArrow>
                    </Row>
                    {expandedIndex === index && (
                        <div style={{ padding: "10px 20px", backgroundColor: "#f8f8f8" }}>
                            <Text>{faq.answer}</Text>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
