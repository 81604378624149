import styled from "styled-components";

export const ContainerMain = styled.div`
    width: 100%;
    height: auto;
    padding: 3% 6% 3% 6%;
`

export const Row = styled.div`
    width: 60%;
    height: 48px;
    display: flex;
    align-items: center;
    border: 1px solid #DFE4EA;
    border-radius: 3px;
    @media (max-width: 600px) { 
        width: 100%;
        margin-bottom: 15px;
    }
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 3px;

    border: none;
`

export const ContainerInput = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 20px;

    @media (max-width: 600px) { 
        flex-direction: column;
        align-items: center;
    }
`