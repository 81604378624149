import React, { useRef, useState } from 'react';
import { ContainerMain, ContainerTitle, Row, Row2, Input, SubmitButton, ContainerSubmit, Image, ContainerGrid, TextHelp, TextRegular, ContainerRow2, ContainerCheck } from './ContactFormElement';
import {  TitleText2 } from '../../styled/AppElement'
import { useTranslation } from 'react-i18next';
import ContactFormImage from '../../assets/images/image-contact-form.svg'
import emailjs from 'emailjs-com';
import {SuccessNotification} from '../SuccessNotification/SuccessNotification';
import { IoChevronDown } from "react-icons/io5";

export const ContactForm = () => {
    const { t } = useTranslation(["contactform"]);
    const form = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [languageDropdown, setLanguageDropdown] = useState(false);
    const [helpDropdown, setHelpDropdown] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedHelp, setSelectedHelp] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_l95afib', 'template_52vg2gz', form.current, 'J4ZbYfmTyfh2f_zPX')
          .then((result) => {
              console.log(result.text);
              form.current.reset();
              setIsChecked(false);
              setShowSuccess(true);
              setTimeout(() => setShowSuccess(false), 3000);
          }, (error) => {
              console.log(error.text);
          });
      };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const toggleDropdown = (dropdown) => {
        if (dropdown === 'language') {
            setLanguageDropdown(!languageDropdown);
            setHelpDropdown(false);
        } else if (dropdown === 'help') {
            setHelpDropdown(!helpDropdown);
            setLanguageDropdown(false);
        }
    };

    const handleOptionSelect = (option, field) => {
        if (field === 'language') {
            setSelectedLanguage(option);
            setLanguageDropdown(false);
        } else if (field === 'help') {
            setSelectedHelp(option);
            setHelpDropdown(false);
        }
    };

    const dropdownStyles = {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        borderRadius: '0 0 10px 10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        color: 'black',
        maxHeight: '200px',
        overflowY: 'auto'
    };

    const optionStyles = {
        padding: '10px 15px',
        cursor: 'pointer',
        textAlign: 'left',
        transition: 'background-color 0.2s',
        ':hover': { backgroundColor: '#f5f5f5' }
    };

    return (
        <ContainerMain>
            {showSuccess && ( 
                <SuccessNotification/>
            )}
            <ContainerTitle>
                <TitleText2>{t("title")}</TitleText2>
            </ContainerTitle>
            <ContainerGrid>
                <div style={{width:"100%", height:"100%"}}>
                    <Image src={ContactFormImage}/>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <Row>
                        <TextRegular style={{ fontWeight:"600"}}>{t("name")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2>
                            <Input style={{borderRadius:"10px"}} id="nombre" name="nombre" type="text" required/>
                        </Row2>
                    </ContainerRow2>
                    <Row>
                        <TextRegular style={{ fontWeight:"600"}}>{t("lastname")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2>
                            <Input style={{borderRadius:"10px"}} id="apellido" name="apellido" type="text"/>
                        </Row2>
                    </ContainerRow2>
                    <Row>
                        <TextRegular style={{ fontWeight:"600"}}>{t("email")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2>
                            <Input style={{borderRadius:"10px"}} id="email" name="email" type="text" required/>
                        </Row2>
                    </ContainerRow2>
                    <Row>
                        <TextRegular style={{ fontWeight:"600"}}>{t("language")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2 style={{ position: 'relative', width: '100%' }}>
                            <Input
                                style={{
                                    borderRadius:"10px",
                                    paddingRight: "30px",
                                    width: "100%"
                                }}
                                id="idioma"
                                name="idioma"
                                type="text"
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}
                                onClick={() => toggleDropdown('language')}
                                required
                            />
                            <IoChevronDown
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: `translateY(-50%) rotate(${languageDropdown ? '180deg' : '0deg'})`,
                                    transition: 'transform 0.3s ease',
                                    pointerEvents: 'none',
                                    color: 'black'
                                }}
                            />
                            {languageDropdown && (
                                <div style={dropdownStyles}>
                                    {['english', 'spanish', 'french'].map((lang) => (
                                        <div
                                            key={lang}
                                            onClick={() => handleOptionSelect(t(`languageOptions.${lang}`), 'language')}
                                            style={optionStyles}
                                        >
                                            {t(`languageOptions.${lang}`)}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Row2>
                    </ContainerRow2>
                    <Row>
                        <TextRegular style={{ fontWeight:"600", textAlign:"left"}}>{t("help")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2 style={{ position: 'relative', width: '100%' }}>
                            <Input
                                style={{
                                    borderRadius:"10px",
                                    paddingRight: "30px",
                                    width: "100%"
                                }}
                                id="ayuda"
                                name="ayuda"
                                type="text"
                                value={selectedHelp}
                                onChange={(e) => setSelectedHelp(e.target.value)}
                                onClick={() => toggleDropdown('help')}
                                required
                            />
                            <IoChevronDown
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: `translateY(-50%) rotate(${helpDropdown ? '180deg' : '0deg'})`,
                                    transition: 'transform 0.3s ease',
                                    pointerEvents: 'none',
                                    color: 'black'
                                }}
                            />
                            {helpDropdown && (
                                <div style={dropdownStyles}>
                                    {['generalInquiry', 'technicalSupport', 'billing'].map((option) => (
                                        <div
                                            key={option}
                                            onClick={() => handleOptionSelect(t(`helpOptions.${option}`), 'help')}
                                            style={optionStyles}
                                        >
                                            {t(`helpOptions.${option}`)}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Row2>
                    </ContainerRow2>
                    <Row>
                        <TextRegular style={{ fontWeight:"600"}}>{t("message")}</TextRegular>
                    </Row>
                    <ContainerRow2>
                        <Row2>
                            <Input style={{borderRadius:"10px"}} id="mensaje" name="mensaje" type="text"/>
                        </Row2>
                    </ContainerRow2>
                    <ContainerSubmit style={{marginTop:"7px", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: "20px", flexWrap: "wrap", width: "80%"}}>
                            <ContainerCheck style={{marginRight: "10px"}}>
                                <input 
                                    type="checkbox" 
                                    id="privacy" 
                                    onChange={handleCheckboxChange} 
                                    required 
                                />
                            </ContainerCheck>
                            <TextHelp style={{fontWeight:"500", textAlign:"left", flex: 1}}>{t("submittext2")}</TextHelp>
                        </div>
                        <TextHelp style={{fontWeight:"700", marginBottom: "10px"}}>{t("submittext1")}</TextHelp>
                        <SubmitButton 
                            style={{
                                width:"auto", 
                                alignSelf: "flex-start",
                                '@media (max-width: 768px)': {
                                    alignSelf: "stretch",
                                    marginTop: "20px"
                                }
                            }} 
                            type="submit" 
                            disabled={!isChecked}
                        >
                            <TextHelp style={{color:"white"}}>{t("submit")}</TextHelp>
                        </SubmitButton>
                    </ContainerSubmit>
                </form>
            </ContainerGrid>
        </ContainerMain>
    )
}