import React, { useRef, useState } from 'react';
import { ContainerMain, Row, Input, ContainerInput } from './InfoEmailElement';
import { Text, TitleText, ButtonContainer } from '../../styled/AppElement'
import { useTranslation } from 'react-i18next';
import ImageBackground from '../../assets/images/image-background-wood.png';
import emailjs from 'emailjs-com';
import {SuccessNotification} from '../SuccessNotification/SuccessNotification'



export const InfoEmail = (props) => {

    const { t } = useTranslation(["infoemail"]);
    const form = useRef();
    const [showSuccess, setShowSuccess] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_l95afib', 'template_fznvvu5', form.current, 'J4ZbYfmTyfh2f_zPX')
          .then((result) => {
              console.log(result.text);
              form.current.reset();
              setShowSuccess(true);
              setTimeout(() => setShowSuccess(false), 9000);
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <ContainerMain style={{backgroundImage:`url(${ImageBackground})`, backgroundSize:"cover", backgroundPosition:"center"}}>
            {showSuccess && ( 
                <SuccessNotification action={() => setShowSuccess(false)}/>
            )}
            <div style={{width:"100%", justifyContent:"center"}}>
                <Text style={{color:"white"}}>{t("title")}</Text>
            </div>
            <div style={{width:"100%", justifyContent:"center"}}>
                <TitleText noUnderline={true} style={{color:"white", textAlign:"center"}}>{t("text1")}</TitleText>
            </div>
            <ContainerInput>
                <form style={{width:"80%", justifyContent:"center", gap:"20px", display:"flex", flexDirection:"column", alignItems:"center"}} ref={form} onSubmit={sendEmail}>
                    <Row style={{width:"100%", maxWidth: "400px"}}>
                        <Input id="email" name="email" type="text" placeholder={t("placeholder")}/>
                    </Row>
                    <ButtonContainer style={{width:"120px", height:"48px", background:"#B68B2C", borderRadius:"30px", justifyContent:"center", display:"flex", alignItems:"center"}} type='submit'>
                        <Text style={{ color:"white" }}>{t("button1")}</Text>
                    </ButtonContainer>
                </form>
            </ContainerInput>
            <div style={{width:"100%", justifyContent:"center"}}>
                <Text style={{color:"white", marginTop:"1%"}}>{t("text2")}</Text>
            </div>
        </ContainerMain>
    )
}