import styled from "styled-components";

export const Page = styled.div`
    text-align: center;
    color: #ffffff;
    height: auto;
`

export const Section = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const SectionContactForm = styled(Section)`
    width: 100%;
    height: auto;
    padding: 3% 21%;

    
    @media (max-width: 1200px) { 
      padding: 3% 9%;
    }

    @media (max-width: 750px) {
      padding: 0;
      padding: 3% 0%;
    }
`

export const Slider = styled.section`
    width: 100%;
    height: 99.9vh;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
`

export const SliderVideo = styled.iframe`
    width: 100%;
    height: 99.9vh;
    border: none;

    @media (max-width: 1400px) { 
      height: 80vh;
    }
    @media (max-width: 900px) { 
      height: 60vh;
    }
    @media (max-width: 600px) { 
      height: 40vh;
    }
`

export const Text = styled.p`
    color: #151515;
    font-size: 16px;
    font-weight: 400;
    margin:0;
    font-family: 'Raleway', sans-serif;

    @media (max-width: 600px) { 
      font-size: 14px;
    }
    @media (max-width: 300px) { 
      font-size: 9px;
    }
`

export const TextServices = styled.text`
    color: #151515;
    font-size: 16px;
    font-weight: 400;
    margin:0;
    font-family: 'Raleway', sans-serif;

    display:flex;
    justify-content:center;
    @media (max-width: 950px) { 
      font-size: 13px;
    }
    @media (max-width: 800px) { 
      font-size: 10px;
    }
    @media (max-width: 650px) { 
      font-size: 8px;
    }
    @media (max-width: 510px) { 
      font-size: 7px;
    }
`

export const TitleText = styled.p`

    color: #151515;
    font-size: 63px;
    margin:0;
    font-family: 'Italiana';

    &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 6%; 
    height: 2px; 
    background-color: #b08f51; 
    display: ${props => props.noUnderline ? 'none' : 'block'}; 
  }

  @media (max-width: 900px) { /* Tablet y smartphones */
    font-size: 39px;
    text-align: left;
  }

  @media (max-width: 400px) { /* Tablet y smartphones */
    font-size: 27px;
  }

  @media (max-width: 300px) { /* Tablet y smartphones */
    font-size: 18px;
  }
`

export const TitleText2 = styled.p`

    color: #151515;
    font-size: 63px;
    margin:0;
    font-family: 'Italiana';
    display: flex;
    justify-content:center;
 
    &::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Ajusta la distancia desde el texto al subrayado */
    width: 9%; /* Ajusta el ancho del subrayado */
    height: 2px; /* Ajusta el grosor del subrayado */
    background-color: #b08f51; /* Color del subrayado */
  }
  @media (max-width: 600px) { /* Tablet y smartphones */
    font-size: 39px;
    text-align: left;
  }
  @media (max-width: 400px) { /* Tablet y smartphones */
    font-size: 27px;
  }
  @media (max-width: 300px) { /* Tablet y smartphones */
    font-size: 18px;
  }
`

export const ButtonContainer = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`

export const ContainerElementServices = styled.div`
`

export const ContainerGridServices = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 0;
`

export const GridServices = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 6%;

    @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 350px) {
        grid-template-columns: 1fr;
    }
`

export const GridServices2 = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 6%;

    @media (max-width: 350px) {
        grid-template-columns: 1fr;
    }
`

export const ImageServices = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 1;
`

export const ImageAboutUs = styled.img`
    height: auto;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    @media (max-width: 800px) {
        aspect-ratio: 1 / 1;
        max-width: 80%;
    }
    @media (max-width: 450px) {
        max-width: 100%;
    }
`

export const ContainerTextServices = styled.div`
    width:100%;
    background:#E8DBBE;
    padding:6px;

    display: flex;
    justify-content: center;
`

export const ContainerMainAboutUs = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    gap: 30px;
    @media (max-width: 800px) { /* Tablet y smartphones */
      grid-template-columns:repeat(1, 1fr);

    }
`

export const TextContainer = styled.div`
    align-items:center;
    display:flex;
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    ul {
        list-style-type: disc;
        padding-left: 20px;

        @media (max-width: 800px) { /* Tablet y smartphones */
          font-size: 12px;
          justify-content: center;
        }
        @media (max-width: 400px) { /* Tablet y smartphones */
          font-size: 7px;
          justify-content: center;
        }
    }
    li {
        margin-bottom: 12%;
        font-size:16px;
        font-family: Raleway;
        font-weight: 500;
        width: 100%;
        @media (max-width: 1300px) { /* Tablet y smartphones */
          font-size: 12px;
        }
        @media (max-width: 1000px) { /* Tablet y smartphones */
          font-size: 9px;
        }
        @media (max-width: 800px) { /* Tablet y smartphones */
          list-style-type: none;
          font-size: 12px;
        }
    }
    @media (max-width: 800px) { /* Tablet y smartphones */
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
`

export const Grid2 = styled.div`
    position:relative;
    width:100%;
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 2%;

  @media (max-width: 1700px) { /* Tablet y smartphones */
    grid-template-columns: 1fr;
  }
`

export const Grid3 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: 350px) {
      grid-template-columns: 1fr;
    }
    
    @media (min-width: 351px) and (max-width: 768px) {
      & > *:last-child {
        grid-column: 1 / -1;
        max-width: calc(50% - 15px);
        margin: 0 auto;
      }
    }
`

export const GridSocial = styled.div`
    position:relative;
    width:100%;
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;
    margin-bottom:6%;

  @media (max-width: 600px) { /* Tablet y smartphones */
    grid-template-columns: 1fr;
  }
`

export const MaterialImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  aspect-ratio: 3 / 4;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const ParallaxHero = styled.section`
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
`

export const TitleTextSmall = styled.p`
    color: #151515;
    font-size: 36px;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;

    @media (max-width: 900px) {
        font-size: 24px;
    }

    @media (max-width: 400px) {
        font-size: 18px;
    }
`

export const SocialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

export const SocialImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1;
`;