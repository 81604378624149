import React from 'react';
import { ContainerMain, Image, TextMain } from './SuccessNotificationElement';
import { Text } from '../../styled/AppElement'
import SuccessIcon from '../../assets/items/success-icon.png'
import ImageClose from '../../assets/items/x-black.svg';



export const SuccessNotification = (props) => {

    return (
        
        <ContainerMain>
            <Image src={SuccessIcon}/>
            <TextMain style={{marginTop:"20px"}}>Envio exitoso</TextMain>
            <Text style={{marginTop:"20px"}}>Pronto nuestro equipo se estará poniendo en contacto con usted en un lapso no mayor a 24h. Recuerde revisar su bandeja de spam.</Text>
            <button onClick={props.action} style={{border:"none", background:"none", position:"absolute", right:"0", marginRight:"3%", top:"0", marginTop:"3%"}}> 
                <Image style={{width:"100%"}} src={ImageClose}/>
            </button>
        </ContainerMain>    
    )
}