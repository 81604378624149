import styled from "styled-components";

export const ContainerMain = styled.div`
    width: 60%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 823px) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    background: #F8F3EA;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    }
    @media (max-width: 450px) {
        width: 150px;
    }
    @media (max-width: 300px) {
        width: 100px;
    }
`

export const ContainerSections = styled.div`
    width: 80%;
    height: 100%;
    display: grid;  
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);

    @media (max-width: 823px) {
        display: block;
        width: 100%;
        justify-content: center;
  }
`

export const ContainerLanguages = styled.div`
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #D9D9D9;

    @media (max-width: 823px) {
        height: auto;
        width: 100%;
    }
`

export const ButtonContainerNavbar = styled.div`
    width: 100%;        
    @media (max-width: 810px) {
        height: auto;
        width: 100%;
        margin-top: 20px;
    }
`
export const Hamburger = styled.div`
  font-size: 30px;
  cursor: pointer;
  display: none;
  color: black;

  @media (max-width: 823px) {
    display: block;
  }
`;