import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContainerImage } from './SliderElement'
import SliderImage1 from '../../assets/images/slider-component-image-1.png'
import SliderImage2 from '../../assets/images/slider-component-image-2.png'
import SliderImage3 from '../../assets/images/slider-component-image-3.png'
import SliderImage4 from '../../assets/images/slider-component-image-4.png'
import SliderImage5 from '../../assets/images/slider-component-image-5.png'
import SliderImage6 from '../../assets/images/slider-component-image-6.png'
import SliderImage7 from '../../assets/images/slider-component-image-7.png'
import SliderImage8 from '../../assets/images/slider-component-image-8.png'
import SliderImage9 from '../../assets/images/slider-component-image-9.png'
import SliderImage10 from '../../assets/images/slider-component-image-10.png'
import SliderImage13 from '../../assets/images/slider-component-image-13.png'
import SliderImage14 from '../../assets/images/slider-component-image-14.png'
import SliderImage15 from '../../assets/images/slider-component-image-15.png'
import SliderImage16 from '../../assets/images/slider-component-image-16.png'
import SliderImage17 from '../../assets/images/slider-component-image-17.png'
import SliderImage18 from '../../assets/images/slider-component-image-18.png'
import SliderImage19 from '../../assets/images/slider-component-image-19.png'
import SliderImage20 from '../../assets/images/slider-component-image-20.png'


const items = [
    {
        src: SliderImage1,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage2,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage3,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage4,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage5,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage6,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage7,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage8,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage9,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage10,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage13,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage14,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage15,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage16,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage17,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage18,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage19,
        altText: '',
        caption: ''
    },
    {
        src: SliderImage20,
        altText: '',
        caption: ''
    }
];

function Slider(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <ContainerImage src={item.src} alt={item.altText}/>
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default Slider;