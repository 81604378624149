import React, { useState, startTransition  } from 'react';
import { } from './HomeElement'
import { Page, Section, Slider, TitleText, TitleText2, Text, ContainerElementServices, ImageServices, ContainerTextServices, TextServices, ContainerGridServices, GridServices, GridServices2, ContainerMainAboutUs, TextContainer, SliderVideo, ImageAboutUs, ButtonContainer, Grid2, Grid3, GridSocial, SectionContactForm, MaterialImage, ParallaxHero, TitleTextSmall, SocialGrid, SocialImage } from '../../styled/AppElement'
import Navbar from '../../components/Navbar/Navbar'; 
import {EmailForm} from '../../components/EmailForm/EmailForm';
import {ContactForm} from '../../components/ContactForm/ContactForm';
import {InfoEmail} from '../../components/InfoEmail/InfoEmail';
import {Footer} from '../../components/Footer/Footer'; 
import BackgroundImageSection1 from '../../assets/images/background-section1-hd.png';
import LogoSection1 from '../../assets/images/logo-section1.svg';
import BackgroundImageSection2 from '../../assets/images/background-section2.png';
import SliderComponent from '../../components/Slider/Slider'; 
import { serviceImages } from '../../utils/serviceImages';  // Add this import
import { useTranslation } from 'react-i18next';
import SliderImage2 from '../../assets/images/slider2.png';
import AboutUsImage1 from '../../assets/images/about-us-image1.png';
import AboutUsImage2 from '../../assets/images/about-us-image2.png';
import SliderImage4 from '../../assets/images/slider-image-4.png';
import SliderImage5 from '../../assets/images/slider-image-5.png'; 
import SliderImage6 from '../../assets/images/slider-image-6.png';
import SliderImage7 from '../../assets/images/slider-image-7.png';
import FAQ from '../../components/FAQ/FAQ'; 
import ImageSocial1 from '../../assets/images/image-social1.png'; 
import ImageSocial2 from '../../assets/images/image-social2.png'; 
import ImageSocial3 from '../../assets/images/image-social3.png'; 
import ImageSocial4 from '../../assets/images/image-social4.png'; 
import ImageSocial5 from '../../assets/images/image-social5.png'; 
import ImageSocial6 from '../../assets/images/image-social6.png'; 
import ImageSocial7 from '../../assets/images/image-social7.png'; 
import ImageSocial8 from '../../assets/images/image-social8.png'; 
import ImageSocial9 from '../../assets/images/image-social9.png'; 
import CerramientosIcono from '../../assets/items/cerramientos-icono.png';
import CubiertasIcono from '../../assets/items/cubiertas-icono.png';
import EstructuraPrimariaIcono from '../../assets/items/estructura-primaria-icono.png';
import Material1 from '../../assets/images/materials-1.png';
import Material2 from '../../assets/images/materials-2.png';
import Material3 from '../../assets/images/materials-3.png';
import { RiWhatsappLine } from "react-icons/ri";
import styled from 'styled-components';

const WhatsAppButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    background-color: #128C7E;
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
`;

export default function Home() {

    const { t } = useTranslation(["home"]);
    const [isEmailFormVisible, setEmailFormVisible] = useState(false); 

    const handleToggleForm = () => {
        startTransition(() => {
            setEmailFormVisible(!isEmailFormVisible);
        });
    };

    return (
        <>
            <Page>
                <ParallaxHero style={{backgroundImage:`url(${BackgroundImageSection1})`}}>
                    <Navbar/> 
                    <img 
                        src={LogoSection1} 
                        alt="Logo" 
                        style={{ 
                            width:"100%",
                            height: '54%',
                            marginTop:"3%"
                        }} 
                    />
                </ParallaxHero>
                <Slider style={{backgroundImage:`url(${BackgroundImageSection2})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <Section id="portfolio" style={{background:"#F8F3EA", padding:"3% 12% 1% 12%", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"10px", display:"flex", justifyContent:"left"}}>
                        <TitleText>{t("sections.section-projects-portfolio.title")}</TitleText>
                    </div>
                    <div style={{ position:"relative", width:"80%", paddingBottom:"20px"}}>
                        <Text style={{ textAlign:"left"}}>{t("sections.section-projects-portfolio.text1")}</Text>
                    </div>
                    <div style={{ position:"relative", display:"flex", justifyContent:"left", marginBottom:"1%"}}>
                        <ButtonContainer onClick={handleToggleForm} style={{width:"200px", height:"48px", background:"#B68B2C", borderRadius:"30px", justifyContent:"center", display:"flex", alignItems:"center"}}>
                            <Text style={{ color:"white" }}>{t("sections.button1")}</Text>
                        </ButtonContainer>
                    </div>
                    <SliderComponent style={{marginBottom:"3%"}}/>
                </Section>
                {isEmailFormVisible && <EmailForm action={handleToggleForm}/>}
                <Section id="services" style={{background:"white", padding:"3% 12%", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"left"}}>
                        <TitleText>{t("sections.section-services.title")}</TitleText>
                    </div>
                    <div style={{position:"relative", width:"80%", paddingBottom:"3%"}}>
                        <Text style={{ textAlign:"left"}}>{t("sections.section-services.text1")}</Text>
                    </div>
                    <GridServices>
                        {[1, 2, 3, 4, 5, 6].map((index) => (
                            <ContainerGridServices key={index}>
                                <ContainerElementServices>
                                    <ImageServices src={serviceImages[index]} alt={`Service ${index}`} />
                                    <ContainerTextServices>
                                        <TextServices style={{ textAlign:"left"}}>{t(`sections.section-services.element${index}`)}</TextServices>
                                    </ContainerTextServices>
                                </ContainerElementServices>
                            </ContainerGridServices>
                        ))}
                    </GridServices>
                    <GridServices2>
                        {[7, 8].map((index) => (
                            <ContainerGridServices key={index}>
                                <ContainerElementServices>
                                    <ImageServices src={serviceImages[index]} alt={`Service ${index}`} />
                                    <ContainerTextServices>
                                        <TextServices style={{ textAlign:"left"}}>{t(`sections.section-services.element${index}`)}</TextServices>
                                    </ContainerTextServices>
                                </ContainerElementServices>
                            </ContainerGridServices>
                        ))}
                    </GridServices2>
                </Section>
                <Slider style={{backgroundImage:`url(${SliderImage2})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <Section id="about-us" style={{background:"#F8F3EA", padding:"3% 12%", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"left"}}>
                        <TitleText>{t("sections.section-aboutus.title")}</TitleText>
                    </div>
                    <div style={{ position:"relative", width:"99%", paddingBottom:"3%"}}>
                        <Text style={{ textAlign:"left"}}>{t("sections.section-aboutus.text1")}</Text>
                    </div>
                    <ContainerMainAboutUs>
                        <div style={{display:"flex", justifyContent:"center"}}> 
                            <ImageAboutUs src={AboutUsImage1}/>
                        </div>  
                        <div style={{display:"flex", justifyContent:"center"}}> 
                            <ImageAboutUs src={AboutUsImage2}/>
                        </div>
                        <TextContainer>
                            <ul>
                                <li>{t("sections.section-aboutus.text3")}</li>
                                <li>Award Female business owner 2019-</li>
                                <li>(TEC design thinking business innovation)</li>
                                <li>{t("sections.section-aboutus.text4")}</li>
                                <li>{t("sections.section-aboutus.text5")}</li>
                                <li>{t("sections.section-aboutus.text6")}</li>
                                <li>{t("sections.section-aboutus.text7")}</li>
                                <li>{t("sections.section-aboutus.text8")}</li>
                                <li>{t("sections.section-aboutus.text9")}</li>
                            </ul>
                        </TextContainer>
                    </ContainerMainAboutUs>
                    <div style={{display:"flex", justifyContent:"left"}}>
                        <div>
                            <Text style={{display:"flex", justifyContent:"left"}}>{t("sections.section-aboutus.text2")}</Text>
                            <TitleTextSmall style={{display:"flex", justifyContent:"left"}}>
                                Catalina Elizondo Arrieta
                            </TitleTextSmall>
                        </div>
                    </div>
                </Section>
                <Section style={{height:"max-content"}}>
                    <SliderVideo 
                        src='https://www.youtube.com/embed/'
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <div id="philosophy" style={{padding:"6% 12%", background:"#F8F3EA"}}>
                        <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"left"}}>
                            <TitleText>{t("sections.section-video1.title")}</TitleText>
                        </div>
                        <Grid2>
                            <Text style={{display:"flex", textAlign:"left", fontWeight:"500"}}>{t("sections.section-video1.text1")}</Text>
                            <Text style={{display:"flex", textAlign:"left", fontWeight:"500"}}>{t("sections.section-video1.text2")}</Text>
                        </Grid2>
                        <div style={{ position:"relative", display:"flex", justifyContent:"left", marginBottom:"2%"}}>
                            <ButtonContainer onClick={handleToggleForm} style={{width:"200px", height:"48px", background:"#B68B2C", borderRadius:"30px", justifyContent:"center", display:"flex", alignItems:"center"}}>
                                <Text style={{ color:"white" }}>{t("sections.button1")}</Text>
                            </ButtonContainer>
                        </div>
                    </div>
                </Section>
                <Section style={{padding:"3% 12%", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"left"}}>
                        <TitleText>{t("sections.section-materials.title")}</TitleText>
                    </div>
                    <div style={{ position:"relative", width:"80%", paddingBottom:"3%"}}>
                        <Text style={{ textAlign:"left"}}>{t("sections.section-materials.text1")}</Text>
                    </div>
                    <Grid3>
                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                            <MaterialImage src={Material1} alt="Material 1" />
                            <div style={{ 
                                position: 'absolute', 
                                bottom: 0, 
                                left: 0, 
                                width: '100%', 
                                height: '50%', 
                                background: 'linear-gradient(to top, rgba(204, 153, 0, 0.8), transparent)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                padding: '5%'
                            }}>
                                <img src={CerramientosIcono} alt="Icon 1" style={{ width: '20%', maxWidth: '90px', height: 'auto' }} />
                                <Text style={{ 
                                    color: 'white', 
                                    marginTop: '10px', 
                                    fontSize: 'clamp(16px, 4vw, 24px)', 
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>{t("sections.section-materials.image1")}</Text>
                            </div>
                        </div>
                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                            <MaterialImage src={Material3} alt="Material 2" />
                            <div style={{ 
                                position: 'absolute', 
                                bottom: 0, 
                                left: 0, 
                                width: '100%', 
                                height: '50%', 
                                background: 'linear-gradient(to top, rgba(204, 153, 0, 0.8), transparent)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                padding: '5%'
                            }}>
                                <img src={EstructuraPrimariaIcono} alt="Icon 2" style={{ width: '20%', maxWidth: '90px', height: 'auto' }} />
                                <Text style={{ 
                                    color: 'white', 
                                    marginTop: '10px', 
                                    fontSize: 'clamp(16px, 4vw, 24px)', 
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>{t("sections.section-materials.image2")}</Text>
                            </div>
                        </div>
                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                            <MaterialImage src={Material2} alt="Material 3" />
                            <div style={{ 
                                position: 'absolute', 
                                bottom: 0, 
                                left: 0, 
                                width: '100%', 
                                height: '50%', 
                                background: 'linear-gradient(to top, rgba(204, 153, 0, 0.8), transparent)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                padding: '5%'
                            }}>
                                <img src={CubiertasIcono} alt="Icon 3" style={{ width: '20%', maxWidth: '90px', height: 'auto' }} />
                                <Text style={{ 
                                    color: 'white', 
                                    marginTop: '10px', 
                                    fontSize: 'clamp(16px, 4vw, 24px)', 
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>{t("sections.section-materials.image3")}</Text>
                            </div>
                        </div>
                    </Grid3>
                </Section>
                <Slider style={{backgroundImage:`url(${SliderImage4})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <Section style={{padding:"3% 12%", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"center"}}>
                        <TitleText2>{t("sections.section-faq1.title")}</TitleText2>
                    </div>
                    <FAQ/>
                </Section>
                <Slider style={{backgroundImage:`url(${SliderImage5})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <SectionContactForm id="contact" style={{background:"#F8F3EA"}}>
                        <ContactForm></ContactForm>
                </SectionContactForm>
                <Slider style={{backgroundImage:`url(${SliderImage6})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <Section style={{padding:"3% 18%", background:"white", height:"auto"}}>
                    <InfoEmail/>
                </Section>
                <Slider style={{backgroundImage:`url(${SliderImage7})`, backgroundSize:"cover", backgroundPosition:"center"}}/>
                <Section style={{padding:"3% 12%", background:"#F8F3EA", height:"auto"}}>
                    <div style={{ position:"relative", marginBottom:"30px", display:"flex", justifyContent:"center"}}>
                        <TitleText2>{t("sections.section-social.title")}</TitleText2>
                    </div>
                    <div style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"3%"}}>
                        <GridSocial style={{width:"50%"}}>
                            <ButtonContainer onClick={() => window.open('https://www.instagram.com/barriovertical?igsh=MTE2eHZ6cHdxbXF1OA==', '_blank')}>
                                <Text style={{fontWeight:"700"}}>Instagram</Text>
                            </ButtonContainer>
                            <ButtonContainer onClick={() => window.open('https://www.facebook.com/profile.php?id=100068384550801', '_blank')}>
                                <Text style={{fontWeight:"700"}}>Facebook</Text>
                            </ButtonContainer>
                            <ButtonContainer>
                                <Text style={{fontWeight:"700"}}>Tiktok</Text>
                            </ButtonContainer>   
                        </GridSocial>
                    </div>
                    <SocialGrid>
                        <SocialImage src={ImageSocial1} alt="Social 1" />
                        <SocialImage src={ImageSocial2} alt="Social 2" />
                        <SocialImage src={ImageSocial3} alt="Social 3" />
                        <SocialImage src={ImageSocial4} alt="Social 4" />
                        <SocialImage src={ImageSocial5} alt="Social 5" />
                        <SocialImage src={ImageSocial6} alt="Social 6" />
                        <SocialImage src={ImageSocial7} alt="Social 7" />
                        <SocialImage src={ImageSocial8} alt="Social 8" />
                        <SocialImage src={ImageSocial9} alt="Social 9" />
                    </SocialGrid>
                </Section>
                <Footer text={t("sections.footer.text1")}/>
            </Page>
            <WhatsAppButton 
                href="https://wa.me/50683022282" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Chat on WhatsApp"
            >
                <RiWhatsappLine />
            </WhatsAppButton>
        </>
    )
}