import styled from "styled-components";

export const ContainerMain = styled.div`

`

export const ContainerImage = styled.img`
    height: 550px;

    @media (max-width: 950px) { 
        height: 450px;
    }
`