import React, { useRef, useState } from 'react';
import { ContainerMain, ContainerTitle, Row, Input, SubmitButton, ContainerSubmit, ContainerSubmitText, Image, ContainerCheck, SelectContainer, SelectHeader, OptionsList, Option, Arrow } from './EmailFormElement';
import { Text, TitleText } from '../../styled/AppElement'
import { useTranslation } from 'react-i18next';
import ImageClose from '../../assets/items/x-black.svg';
import emailjs from 'emailjs-com';
import {SuccessNotification} from '../SuccessNotification/SuccessNotification'
import { IoChevronDown } from "react-icons/io5";

export const EmailForm = (props) => {
    const { t } = useTranslation(["emailform"]);
    const form = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [language, setLanguage] = useState('');
    const [help, setHelp] = useState('');
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);

    const languageOptions = [
        t("languageOptions.english"),
        t("languageOptions.spanish"),
        t("languageOptions.french"),
        t("languageOptions.german")
    ];
    const helpOptions = [
        t("helpOptions.generalInquiry"),
        t("helpOptions.technicalSupport"),
        t("helpOptions.billing"),
        t("helpOptions.other")
    ];

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_l95afib', 'template_52vg2gz', form.current, 'J4ZbYfmTyfh2f_zPX')
          .then((result) => {
              console.log(result.text);
              form.current.reset();
              setIsChecked(false);
              setShowSuccess(true);
              setTimeout(() => setShowSuccess(false), 3000);
          }, (error) => {
              console.log(error.text);
          });
      };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        
        <ContainerMain>
            {showSuccess && ( 
                <SuccessNotification/>
            )}
            <button onClick={props.action} style={{border:"none", background:"none", position:"absolute", right:"0", marginRight:"3%"}}> 
                <Image style={{width:"100%"}} src={ImageClose}/>
            </button>
            <ContainerTitle>
                <TitleText noUnderline={true}>{t("title")}</TitleText>
            </ContainerTitle>
            <form ref={form} onSubmit={sendEmail}>
                <Row>
                    <Text >{t("name")}</Text>
                </Row>
                <Row>
                    <Input id="nombre" name="nombre" type="text" required/>
                </Row>
                <Row>
                    <Text >{t("lastname")}</Text>
                </Row>
                <Row>
                    <Input id="apellido" name="apellido" type="text"/>
                </Row>
                <Row>
                    <Text >{t("email")}</Text>
                </Row>
                <Row>
                    <Input id="email" name="email" type="text" required />
                </Row>
                <Row>
                    <Text >{t("language")}</Text>
                </Row>
                <Row>
                    <SelectContainer>
                        <SelectHeader onClick={() => setIsLanguageOpen(!isLanguageOpen)}>
                            <Input
                                id="idioma"
                                name="idioma"
                                type="text"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                required
                            />
                            <Arrow as={IoChevronDown} style={{ transform: isLanguageOpen ? 'rotate(180deg)' : 'rotate(0deg)', color: "black" }} />
                        </SelectHeader>
                        {isLanguageOpen && (
                            <OptionsList>
                                {languageOptions.map((option, index) => (
                                    <Option key={index} onClick={() => {
                                        setLanguage(option);
                                        setIsLanguageOpen(false);
                                    }}>
                                        {option}
                                    </Option>
                                ))}
                            </OptionsList>
                        )}
                    </SelectContainer>
                </Row>
                <Row>
                    <Text >{t("help")}</Text>
                </Row>
                <Row>
                    <SelectContainer>
                        <SelectHeader onClick={() => setIsHelpOpen(!isHelpOpen)}>
                            <Input
                                id="ayuda"
                                name="ayuda"
                                type="text"
                                value={help}
                                onChange={(e) => setHelp(e.target.value)}
                                required
                            />
                            <Arrow as={IoChevronDown} style={{ transform: isHelpOpen ? 'rotate(180deg)' : 'rotate(0deg)', color: "black" }} />
                        </SelectHeader>
                        {isHelpOpen && (
                            <OptionsList>
                                {helpOptions.map((option, index) => (
                                    <Option key={index} onClick={() => {
                                        setHelp(option);
                                        setIsHelpOpen(false);
                                    }}>
                                        {option}
                                    </Option>
                                ))}
                            </OptionsList>
                        )}
                    </SelectContainer>
                </Row>
                <ContainerSubmit style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <ContainerSubmitText style={{width: "100%"}}>
                        <Row style={{height:"50%"}}>
                            <Text style={{textAlign:"left", fontWeight:"700"}}>{t("submittext1")}</Text>
                        </Row>
                        <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginTop: "20px", width: "100%"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <ContainerCheck>
                                    <input 
                                        type="checkbox" 
                                        id="privacy" 
                                        onChange={handleCheckboxChange} 
                                        required 
                                    />
                                </ContainerCheck>
                                <Text style={{textAlign:"left", marginLeft: "10px"}}>{t("submittext2")}</Text>
                            </div>
                            <SubmitButton type="submit" disabled={!isChecked} style={{marginLeft: "20px", flexGrow: 1, maxWidth: "200px", width: "auto", alignSelf: "center", marginTop: "20px"}}>
                                <Text style={{ color:"white"}}>{t("submit")}</Text>
                            </SubmitButton>
                        </div>
                    </ContainerSubmitText>
                </ContainerSubmit>
            </form>
        </ContainerMain>
    )
}