import ImageServices1 from '../assets/images/image-services-1-yellow.png';
import ImageServices2 from '../assets/images/image-services-2-yellow.png';
import ImageServices3 from '../assets/images/image-services-3-yellow.png';
import ImageServices4 from '../assets/images/image-services-4-yellow.png';
import ImageServices5 from '../assets/images/image-services-5-yellow.png';
import ImageServices6 from '../assets/images/image-services-6-yellow.png';
import ImageServices7 from '../assets/images/image-services-7-yellow.png';
import ImageServices8 from '../assets/images/image-services-8-yellow.png';

export const serviceImages = {
    1: ImageServices1,
    2: ImageServices2,
    3: ImageServices3,
    4: ImageServices4,
    5: ImageServices5,
    6: ImageServices6,
    7: ImageServices7,
    8: ImageServices8,
};