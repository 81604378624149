import styled from "styled-components";

export const ContainerMain = styled.div`
    width: 36%;
    justify-content: center;
    background-color: #F8F3EA;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 8px 15px 0px #00000026;
    padding: 3% 1% 3% 1%;
    z-index: 1000;
    @media (max-width: 1200px) { /* Tablet y smartphones */
        width: 70%;
    }
    @media (max-width: 600px) { /* Tablet y smartphones */
        width: 80%;
    }
`

export const ContainerTitle = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) { /* Tablet y smartphones */
        margin-top: 6%;
    }
`
export const Row = styled.div`
    width: 100%;
    height: 6%;
    display: flex;
    align-items: center;
`

export const ContainerCheck = styled.div`
    width: 9%;
    height: 6%;
    display: flex;
    align-items: center;
`

export const SubmitButton = styled.button`
    width: 24%;
    height: 54%;
    background: #B68B2C;
    border: none;
    font-size: 20px;
    cursor: pointer;
    right: 0;
    
    border-radius: 27px 27px 27px 27px;
    padding: 9px;
`

export const ContainerSubmit = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;

    margin-top: 20px;

    @media (max-width: 600px) { /* Tablet y smartphones */
        display: block;
    }
`

export const ContainerSubmitText = styled.div`
    width: 76%;
    height: 54%;
`

export const Image = styled.img`
    width: 100%;
`

export const SelectContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const SelectHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: white;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const OptionsList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
`;

export const Option = styled.li`
    padding: 10px;
    cursor: pointer;
    text-align: left;  // Add this line to align text to the left
    color: black;
    &:hover {
        background-color: #f0f0f0;
    }
`;

export const Arrow = styled.img`
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    background: white;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;  // Add this line to align input text to the left

    ${Option} & {
        border: none;
        background: transparent;
        width: 100%;
        padding: 0;

        &:focus {
            outline: none;
        }
    }
`
