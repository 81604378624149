import styled from "styled-components";

export const ContainerMain = styled.div`
    width: 100%;
    height: 270px;
    padding: 9% 12% 9% 12%;

    align-items: center;
    display:flex;
`

export const ContainerGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
`

export const ContainerImage = styled.img`
`

export const ContainerGridSocial = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    align-items:center;
    justify-content:center;

    gap: 9px;
    @media (max-width: 600px) { /* Tablet y smartphones */
        grid-template-columns: repeat(1, 1fr);
    }
`